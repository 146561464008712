.contact__container {
  grid-template-columns: repeat(2, max-content);
  justify-content: center;
  column-gap: 6rem;
  margin-bottom: 3rem;
}

.contact__title {
  text-align: center;
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1-5);
}

.contact__info {
  display: grid;
  row-gap: 1rem;
  grid-template-columns: 300px;
}

.contact__card {
  background-color: var(--container-color);
  border: 1px solid var(--border-color);
  padding: 1rem;
  border-radius: 0.2rem;
  text-align: center;
}

.contact__card-icon {
  font-size: 2rem;
  color: var(--title-color);
  margin-bottom: var(--mb-1-5);
}

.contact__card-title {
  font-weight: var(--font-medium);
}

.contact__card-data {
  display: block;
  margin-bottom: var(--mb-0-75);
}

.contact__form {
  width: 360px;
}

.contact__form-unit {
  position: relative;
  margin-bottom: var(--mb-2);
  height: 3.5rem;
}

.contact__form-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid rgba(0, 0, 0, 0.3);
  background: none;
  border-radius: 0.5rem;
  padding: 1.5rem;
  z-index: 1;
}

.contact__form-tag {
  position: absolute;
  top: -0.75rem;
  left: 1.25rem;
  font-size: var(--smaller-font-size);
  padding: 0.25rem;
  background-color: var(--body-color);
  z-index: 2;
}

.contact__form-area {
  height: 11rem;
}

/* BREAKPOINTS */
/* For large devices */
@media screen and (max-width: 992px) {
  .contact__container {
    column-gap: 3rem;
  }
}

/* For medium devices */
@media screen and (max-width: 786px) {
  .contact__container {
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }

  .contact__info {
    grid-template-columns: 1fr;
    justify-content: center;
  }

  .contact__form {
    width: 100%;
    margin: 0 auto;
  }
}
