.portfolio__container {
  grid-template-columns: repeat(3, max-content);
  gap: 2rem;
  justify-content: center;
}

.portfolio__item {
  background-color: var(--container-color);
  border: 1px solid var(--border-color);
  padding: 0.5rem;
  border-radius: 0.2rem;
}

.portfolio__item-image {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 350px;
  height: auto;
  border-radius: 0.2rem;
  margin-bottom: var(--mb-0-5);
  cursor: zoom-in;
}

.portfolio__item-image img {
  transition: all 0.5s;
}

.portfolio__item-image:hover img {
  transform: scale(1.3);
}

.portfolio__item-title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-5);
}

.portfolio__item-links {
  display: inline-flex;
  align-items: center;
  margin-top: 5px;
}

.portfolio__item-link {
  color: var(--text-color);
  font-size: var(--small-font-size);
  display: flex;
  margin-right: 1rem;
}

.portfolio__item-icon {
  font-size: var(--normal-font-size);
  margin-right: 4px;
}

.portfolio__slider-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio__slider-overlay img {
  max-width: 80vw;
}

.portfolio__slider-prev,
.portfolio__slider-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2.5rem;
  padding: 1rem 1.5rem;
  color: #fff;
  cursor: pointer;
}

.portfolio__slider-prev {
  left: 0;
}

.portfolio__slider-next {
  right: 0;
}

.portfolio__slider-close {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  font-size: 2rem;
  color: white;
  cursor: pointer;
}

/* BREAKPOINTS */
/* For large devices */
@media screen and (max-width: 1450px) {
  .portfolio__container {
    grid-template-columns: repeat(2, max-content);
  }
}

@media screen and (max-width: 850px) {
  .portfolio__item-image {
    width: 300px;
  }

  .portfolio__slider-overlay img {
    object-fit: scale-down;
  }
}

@media screen and (max-width: 700px) {
  .portfolio__container {
    grid-template-columns: 1fr;
  }

  .portfolio__item-image {
    width: 100%;
    margin-bottom: var(--mb-0-5);
  }
}

@media screen and (max-width: 540px) {
  .portfolio__slider-prev,
  .portfolio__slider-next {
    font-size: 2.5rem;
    padding: 0;
  }
}
