.scrollup {
  position: fixed;
  right: 2.5rem;
  bottom: -20%;
  padding: 0.2rem 0.5rem;
  background-color: var(--title-color);
  opacity: 0.8;
  border-radius: 50%;
  z-index: var(--z-tooltip);
  transition: 0.5s;
}

.scrollup__icon {
  font-size: 1.5rem;
  color: #fff;
  transition: 0.3s;
}

.scrollup:hover {
  background-color: var(--title-color-dark);
}

.visible {
  bottom: 3rem;
}

/* BREAKPOINTS */
/* For large devices */
@media screen and (max-width: 992px) {
  .scrollup {
    right: 1.5rem;
    padding: 0.15rem 0.4rem;
  }

  .scrollup__icon {
    font-size: 1.25rem;
  }
}

/* For medium devices */
@media screen and (max-width: 786px) {
  .visible {
    bottom: 5rem;
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .scrollup {
    right: 1rem;
  }
}
