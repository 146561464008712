.experience__container {
  max-width: 768px;
}

.experience__content {
  display: grid;
  grid-template-columns: 0.5fr;
  justify-content: center;
}

.experience__item {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  column-gap: 1.5rem;
}

.experience__item-title {
  color: var(--title-color);
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
}

.experience__item-subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-0-5);
}

.experience__item-date {
  font-size: var(--small-font-size);
}

.experience__item-indicator {
  display: inline-block;
  width: 13px;
  height: 13px;
  background: var(--text-color);
  border-radius: 50%;
}

.experience__item-line {
  display: block;
  width: 1px;
  height: 100%;
  background: var(--text-color);
  transform: translate(6px, -7px);
}

/* BREAKPOINTS */
/* For large devices */
@media screen and (max-width: 992px) {
  .experience__container {
    margin-left: auto;
    margin-right: auto;
  }
}

/* For medium devices */
@media screen and (max-width: 786px) {
  .experience__container {
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
  }
}

@media screen and (max-width: 576px) {
  .experience__content {
    grid-template-columns: initial;
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .experience__item {
    gap: 1rem;
  }
}
