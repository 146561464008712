.skill__container {
  grid-template-columns: repeat(3, max-content);
  justify-content: center;
  column-gap: 2rem;
}

.skill__content {
  position: relative;
  border: 1px solid var(--border-color);
  background-color: var(--container-color);
  width: 366px;
  padding: 3rem 2rem 3rem 2rem;
}

.skill__icon {
  display: block;
  font-size: 2rem;
  color: var(--title-color);
  margin-bottom: var(--mb-1);
}

.skill__content-title {
  margin-bottom: var(--mb-1);
}

.skill__content-items {
  row-gap: 0.5rem;
}

.skill__content-item {
  display: flex;
  align-items: start;
  column-gap: 0.5rem;
}

.skill__content-item i {
  color: var(--title-color);
  font-size: 1rem;
}

.skill__content-item p {
  font-size: var(--small-font-size);
}

/* BREAKPOINTS */
/* For large devices */
@media screen and (max-width: 1450px) {
  .skill__container {
    grid-template-columns: repeat(2, max-content);
  }
}

@media screen and (max-width: 850px) {
  .skill__content {
    width: 316px;
  }
}

@media screen and (max-width: 700px) {
  .skill__container {
    grid-template-columns: 1fr;
  }

  .skill__content {
    width: 100%;
  }
}
