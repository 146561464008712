.footer {
  background-color: var(--container-color);
  border-top: 1px solid var(--container-color);
}

.footer__container {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
